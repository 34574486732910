@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}
.wrapper {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;

  width: fit-content;
  margin: 0 auto;
}

.textWrapper {
  position: relative;

  padding: 30px;
  @media screen and(max-width: 800px) {
    padding: 10px;
  }
}

.leftQuote,
.rightQuote {
  position: absolute;

  width: 5%;
  svg {
    width: 100%;
    height: 100%;
  }
}
.leftQuote {
  top: 0;
  left: 0;
}
.rightQuote {
  right: 0;
  bottom: 0;

  transform: rotate(180deg);
}
.title {
  margin: 0 0 50px;
}

.authorWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and(max-width: 1280px) {
    margin: 40px 0 0;
  }
  @media screen and(max-width: 800px) {
    margin: 25px 0 0;
  }
}

.authorNameWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.authorName,
.authorPosition {
  @include responsiveFontSize(20px, 24px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(18px, 20px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(14px, 18px, 500px, 1280px);
  }
}

.authorName {
  margin: 0 0 6px;

  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
}

.authorPosition {
  margin: 0;

  text-align: center;
  white-space: break-spaces;
  letter-spacing: -0.03em;

  color: rgba(255, 255, 255, 0.7);

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 120%;
}
