@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}
.wrapper {
  display: flex;
  // overflow: auto;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
}

.drawerRow {
  display: flex;
  flex-direction: column;
  &:last-of-type {
    margin: 0 0 140px;
  }

  @media screen and (max-width: 1280px) {
    &:last-of-type {
      margin: 0px 0 80px;
    }
  }
  @media screen and (max-width: 800px) {
    &:last-of-type {
      margin: 0 0 20px;
    }
  }
}

.drawerHeader {
  position: relative;

  display: flex;
  justify-content: space-between;

  margin: 0 0 60px;
  @media screen and (max-width: 800px) {
    margin: 0 0 22px;
  }
}

.drawerHeaderTitle {
  margin: 0;

  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 100%;

  @include responsiveFontSize(50px, 60px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(40px, 50px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(38px, 40px, 500px, 1280px);
  }
}

.drawerHeaderCloseBtnWrapper {
  position: absolute;
  top: 0;
  right: 0;
z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.drawerHeaderCloseBtn {
  cursor: pointer;

  border: none;
  outline: none;
  background: none;
}

.emailInputWrapper {
  display: flex;
  flex-direction: column;
}

.emailInput {
  margin: 0 0 16px;
  padding: 18px 30px;

  letter-spacing: -0.03em;

  color: #ffffff;
  border-radius: 16px;
  outline: none;
  background: rgba(255, 255, 255, 0.09);

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 130%;
  transition: border .3s ease-in-out, color .3s ease-in-out;
  border: 1px solid transparent;
&.onError {
  border: 1px solid #FF7777;
  color: #FF7777
}
  @include responsiveFontSize(24px, 24px, 1600px, 1900px);
  backdrop-filter: blur(12.5px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(16px, 24px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
  }
}

.emailInputPrivacy {
  margin: 0 15px 15px;

  letter-spacing: -0.05em;

  opacity: 0.5;
  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 110%;

  @include responsiveFontSize(16px, 18px, 1280px, 1900px);
}
.emailInputPrivacyLink {
  text-decoration: underline;

  color: #ffffff;

  text-underline-offset: 10px;
  &:hover {
    text-decoration: underline;

    color: #ffffff;
  }
  @media screen and(max-width: 900px) {
    text-underline-offset: 5px;
  }
}

.drawerSwitchWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 30px;

  border-radius: 40px;
  background: rgba(255, 255, 255, 0.09);

  backdrop-filter: blur(12.5px);
  &:not(:last-child) {
    margin: 0 0 18px;
  }
  @media screen and(max-width: 900px) {
    padding: 16px;

    border-radius: 20px;
  }
}

.drawerSwitchPresentation,
.drawerSwitchResearch {
  display: flex;
  align-items: center;

  margin: 0 24px 0 0;

  &:hover label:after {
    opacity: 0.7;
  }
  input[type='checkbox'] {
    visibility: hidden;

    width: 0;
    height: 0;
  }

  label {
    position: relative;

    display: block;

    width: 80px;
    height: 40px;

    cursor: pointer;
    text-indent: -9999px;

    border-radius: 100px;
    background: grey;

    will-change: transform, left;

    @media screen and(max-width: 900px) {
      width: 60px;
      height: 30px;
    }
  }

  label:after {
    position: absolute;
    top: 4px;
    left: 4px;

    width: 32px;
    height: 32px;

    content: '';
    transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;

    border-radius: 90px;
    background: #fff;

    will-change: opacity, left;
    @media screen and(max-width: 900px) {
      top: 3px;
      left: 3px;

      width: 24px;
      height: 24px;
    }
  }

  input:checked + label:after {
    left: calc(100% - 36px);
    @media screen and(max-width: 900px) {
      left: calc(100% - 27px);
    }
  }
}

.drawerSwitchPresentation {
  input:checked + label {
    background: #e954c3;
  }
}

.drawerSwitchResearch {
  input:checked + label {
    background: #30d6ed;
  }
}

.drawerSwitchTextWrapper {
  display: flex;
  flex-direction: column;
}

.drawerSwitchTitle {
  margin: 0 0 6px;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 100%;

  @include responsiveFontSize(16px, 18px, 1280px, 1900px);
}

.drawerSwitchDesc {
  margin: 0;

  color: rgba(255, 255, 255, 0.7);

  font-family: 'Graphik LC Web';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 113%;
}

.submitBtn {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  padding: 42px 0;

  cursor: pointer;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  letter-spacing: -0.05em;

  color: #ffffff;
  border: none;
  border-radius: 54px 54px 0px 0px;
  outline: none;
  background: #3480f1;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 100%;

  @include responsiveFontSize(24px, 36px, 1280px, 1900px);
  &:hover {
    color: #0b0721;
    background: #ffffff;
  }
  @media screen and (max-width: 1280px) {
    padding: 28px 0;
  }
  @media screen and (max-width: 800px) {
    position: relative;

    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 18px;

    border-radius: 50px;
  }
}

.successBody {
  margin: auto;
}

.successTitle {
  margin: 0;

  text-align: center;
  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 700;
  font-style: normal;
  line-height: 100%;

  @include responsiveFontSize(60px, 80px, 1280px, 1900px);
}
.successSubTitle {
  margin: 0;

  text-align: left;
  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 100%;

  @include responsiveFontSize(60px, 80px, 1280px, 1900px);
}

.successDescWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.successDescIcon {
  margin: 40px 0 0 40px;
}
.successDesc {
  margin: 0;

  text-align: center;
  letter-spacing: -0.03em;

  color: rgba(255, 255, 255, 0.6);

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 130%;

  @include responsiveFontSize(24px, 36px, 1600px, 1900px);
}

.successFooter {
  display: flex;
  flex-direction: column;
}

.successFooterDesc {
  margin: 0 0 16px;

  letter-spacing: -0.03em;

  color: rgba(255, 255, 255, 0.6);

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 120%;

  @include responsiveFontSize(18px, 24px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(16px, 18px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(14px, 16px, 500px, 1280px);
  }
}

.successFooterLinkBtn,
.successFooterBackBtn {
  width: 100%;
  padding: 25px 0;

  cursor: pointer;
  text-align: center;

  border-radius: 100px;
  &:not(:last-child) {
    margin: 0 0 20px;
  }
}

.successFooterLinkBtn,
.successFooterBackBtn {
  @include responsiveFontSize(32px, 36px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(20px, 24px, 500px, 1280px);
  }
}

.successFooterLinkBtn {
  letter-spacing: -0.05em;

  color: #ffffff;
  background: #3480f1;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 80%;
}

.successFooterBackBtn {
  letter-spacing: -0.05em;

  color: #030c1d;
  border: none;
  outline: none;
  background: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 80%;
}
