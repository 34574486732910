@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}

.wrapper {
  position: sticky;
  z-index: 10;
  top: 0;

  overflow: hidden;

  max-width: 1920px;
  margin: 0 auto 100px;

  transition: top 0.3s ease-in-out;
  transform: translateZ(0);

  will-change: top;
  &.onScroll {
    top: 20px;
  }
  @media screen and (max-width: 1050px) {
    position: static;
  }
}

.container {
  display: flex;
  justify-content: space-between;

  padding: 35px 40px;

  transition: background 0.3s ease-in-out;
  transform: translateZ(0);

  border-radius: 26px;

  will-change: background, backdrop-filter;
  @media screen and (max-width: 1600px) {
    padding: 20px 25px;
  }
  @media screen and (max-width: 800px) {
    padding: 20px 0px;
  }
  &.onScroll {
    background: rgba(246, 247, 251, 0.09);

    backdrop-filter: blur(27px);
  }
}

.leftSide,
.rightSide,
.navList {
  display: flex;
  align-items: center;
}

.leftSide {
  @media screen and (max-width: 1050px) {
    width: 100%;
    justify-content: space-between;
  }
}
.logoWrapper {
  display: flex;
  align-items: center;
}
.logo {
  margin: 0 7px 0 0;
  @media screen and (max-width: 1050px) {
    width: 100px;
  }
}

.logoDesc {
  margin: 0 36px 0 0;

  font-family: 'Graphik LC Web';
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  line-height: 100%;
  @media screen and (max-width: 1600px) {
    margin: 0 8px 0 0;

    font-size: 14px;
  }
}

.mojo {
  color: rgba(255, 255, 255, 0.5);

  font-family: 'Graphik LC Web';
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  line-height: 110%;
  @media screen and (max-width: 1600px) {
    font-size: 16px;
  }
}

.rightSide {
}

.navLinks {
  transition: transform 0.3s ease-in-out;
  transform: translateZ(0);

  will-change: transform;

  &.onScroll {
    transform: translate(-250px, 0);
    @media screen and (max-width: 1600px) {
      transform: translate(-180px, 0);
    }
  }
}

.navItem {
  &:not(:last-child) {
    margin: 0 40px 0 0;
  }
  @media screen and (max-width: 1600px) {
    &:not(:last-child) {
      margin: 0 15px 0 0;
    }
  }
}

.anchorLink {
  transition: color 0.3s ease-in-out;
  text-decoration: none;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 140%;

  @include responsiveFontSize(16px, 20px, 1280px, 1900px);
  &:hover {
    color: #30d6ed;
  }
}

.btn {
  position: absolute;
  right: -250px;

  padding: 10px 45px;

  cursor: pointer;
  transition: right 0.3s ease-out, opacity 0.3s ease-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transform: translateZ(0);

  opacity: 0;
  color: #ffffff;
  border: none;
  border-radius: 1000px;
  outline: none;
  background-color: #030c1d;

  font-family: 'Graphik LC Web';
  font-size: 24px;
  font-weight: 400;
  font-style: italic;
  line-height: 120%;

  will-change: opacity, right;
  @media screen and (max-width: 1600px) {
    padding: 9px 30px;

    font-size: 16px;
  }
  &.onScroll {
    right: 40px;

    opacity: 1;
    @media screen and (max-width: 1600px) {
      right: 30px;
    }
  }
  &:hover {
    color: #030c1d;
    background-color: #ffffff;
  }
}
