.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
}

.drawerRow {
  display: flex;
  flex-direction: column;
  &:last-of-type {
    margin: 0 0 140px;
  }

  @media screen and (max-width: 1280px) {
    &:last-of-type {
      margin: 0px 0 80px;
    }
  }
  @media screen and (max-width: 800px) {
    &:last-of-type {
      margin: 0 0 20px;
    }
  }
}

.drawerHeader {
  position: relative;

  display: flex;
  justify-content: space-between;

  margin: 0 0 60px;
  @media screen and (max-width: 800px) {
    margin: 0 0 22px;
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin: 0 28px 0;
}

.logoWrapper {
  display: flex;
  align-items: center;
}
.logo {
  margin: 0 7px 0 0;
  @media screen and (max-width: 1050px) {
    width: 100px;
  }
}
.logoDesc {
  margin: 0 36px 0 0;

  font-family: 'Graphik LC Web';
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  line-height: 100%;
  @media screen and (max-width: 1600px) {
    margin: 0 15px 0 0;

    font-size: 14px;
  }
}

.mojo {
  color: rgba(255, 255, 255, 0.5);

  font-family: 'Graphik LC Web';
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  line-height: 110%;
  @media screen and (max-width: 1600px) {
    font-size: 16px;
  }
}

.drawerHeaderCloseBtnWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.drawerHeaderCloseBtn {
  cursor: pointer;

  border: none;
  outline: none;
  background: none;
}

.navList {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
}

.navItem {
  display: flex;
}

.anchorLink {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  text-decoration: none;

  color: rgba(255, 255, 255, 0.5);

  font-family: 'Graphik LC Web';
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  line-height: 140%;
  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
  &:hover .linkArrow {
    path {
      fill-opacity: 0.8;
    }
  }
}

.anchorLinkText {
  display: flex;
  align-items: center;

  margin: 0 8px 0 0;
}

.anchorLinkArrow {
  display: flex;
  align-items: center;

  width: 39px;
  height: 39px;

  transform: rotate(45deg);
  path {
    transition: fill-opacity.3s ease-in-out;

    fill-opacity: 0.5;
  }
}

.contactsWrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  margin: 0 0 30px;

  gap: 6px;
  & :nth-child(1) {
    justify-content: flex-start;
  }
  & :nth-child(2) {
    justify-content: flex-end;
  }
}

.contactsMobileWrapper {
  display: flex;

  width: 100%;

  gap: 6px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  padding: 10px 24px;

  transition: background 0.3s ease-in-out;
  text-decoration: none;

  color: rgba(255, 255, 255, 0.9);
  border-radius: 22px;
  background: rgba(255, 255, 255, 0.09);

  font-family: 'Graphik LC Web';
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 130%;

  backdrop-filter: blur(12.6692px);
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

.linkIcon {
  width: 30px;
  height: auto;
  &:not(:only-child) {
    margin: 0 8px 0 0;
  }
}
.linkText {
  white-space: nowrap;
}

.submitBtn {
  position: relative;

  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 18px;

  cursor: pointer;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  letter-spacing: -0.05em;

  color: #ffffff;
  border: none;
  border-radius: 50px;
  outline: none;
  background: #E954C3;

  font-family: 'Graphik LC Web';
  font-size: 24px;
  font-weight: 400;
  font-style: italic;
  line-height: 100%;

  &:hover {
    color: #0b0721;
    background: #ffffff;
  }
}
