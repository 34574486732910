@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}
.wrapper {
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: flex-start;

  margin: 0 0 260px 0;
  padding: 60px 0 170px;
  @media screen and(max-width: 1600px) {
    margin: 0 0 120px;
  }
  @media screen and(max-width: 1280px) {
    margin: 0 0 100px;
  }
  @media screen and(max-width: 850px) {
    justify-content: center;
  }
}

.title {
  margin: 0;

  transition: opacity 1s ease-in-out;
  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 90%;

  @include responsiveFontSize(140px, 160px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(120px, 140px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(50px, 120px, 500px, 1280px);
  }
  & > :nth-child(1) {
    margin: 0 0 0 3%;
  }
  & > :nth-child(5) {
    margin: 0 0 0 13%;
  }
  .transparent {
    opacity: 0.6;
  }
  .normal {
    opacity: 0.6;
    &:nth-of-type(1) {
      transition: opacity 0.5s ease-in-out;
    }
    &:nth-of-type(3) {
      transition: opacity 0.5s ease-in-out 0.2s;
    }
  }
  &.animActivated .normal {
    opacity: 1;
  }
}

.popupDirectors,
.popupEcommerce,
.popupAgencies,
.popupFounders,
.popupCEOs {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  padding: 10px 30px 10px 10px;

  letter-spacing: -0.03em;

  opacity: 0;
  color: #ffffff;
  border-radius: 170px 40px 40px 170px;
  background: rgba(255, 255, 255, 0.14);

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 100%;

  @include responsiveFontSize(36px, 40px, 1600px, 1900px);
  will-change: transform, opacity;
  backdrop-filter: blur(15px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(32px, 36px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    padding: 10px 20px 10px 10px;

    @include responsiveFontSize(18px, 32px, 500px, 1280px);
  }
  @media screen and(max-width: 850px) {
    padding: 6px 16px 6px 6px;

    border-radius: 170px 16px 16px 170px;
  }
}

.arrow {
  position: absolute;
  top: 0;
  left: 0;

  transform: translate(-50%, -50%);
  @media screen and(max-width: 1600px) {
    width: 28px;
    height: auto;
  }
  @media screen and(max-width: 1280px) {
    width: 23px;
    height: auto;
  }
  @media screen and(max-width: 850px) {
    width: 14px;
    height: auto;
  }
}

.emojiWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100px;
  height: 100px;
  margin: 0 25px 0 0;

  border-radius: 50%;
  background: rgba(255, 255, 255, 0.14);
  svg {
    width: 80%;
  }
  @media screen and(max-width: 1600px) {
    width: 90px;
    height: 90px;
  }
  @media screen and(max-width: 1280px) {
    width: 72px;
    height: 72px;
  }
  @media screen and(max-width: 850px) {
    width: 40px;
    height: 40px;
    margin: 0 8px 0 0;
  }
}

.popupDirectors {
  top: -30px;
  right: 20%;

  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  transform: translate(50%, 50%);
  .arrow {
    path {
      fill: #d79c57;
    }
  }
  .emojiWrapper {
    border: 6px solid #d79c57;
    @media screen and(max-width: 850px) {
      border: 2px solid #d79c57;
    }
  }
  @media screen and(max-width: 850px) {
    top: -20px;
  }
  &.animActivated {
    transform: translate(0, 0);

    opacity: 1;
  }
  // &.move {
  //   animation: run 2s 2 ease-in-out;
  // }
}

.popupEcommerce {
  top: 90px;
  left: 30%;

  transition: transform 0.3s ease-out 0.1s, opacity 0.3s ease-out 0.1s;
  transform: translate(-50%, -50%);
  .arrow {
    path {
      fill: #e954c3;
    }
  }
  .emojiWrapper {
    border: 6px solid #e954c3;
    @media screen and(max-width: 850px) {
      border: 2px solid #e954c3;
    }
  }
  @media screen and(max-width: 850px) {
    top: 70px;
    right: 10%;
    left: unset;
  }
  &.animActivated {
    transform: translate(0, 0);

    opacity: 1;
  }
  // &.move {
  //   animation: run 2s 2 ease-in-out;
  // }
}

.popupAgencies {
  bottom: 0;
  left: 1%;

  transition: transform 0.3s ease-out 0.2s, opacity 0.3s ease-out 0.2s;
  transform: translate(-50%, -50%);
  .arrow {
    path {
      fill: #ae48ed;
    }
  }
  .emojiWrapper {
    border: 6px solid #ae48ed;
    @media screen and(max-width: 850px) {
      border: 2px solid #ae48ed;
    }
  }
  @media screen and(max-width: 850px) {
    left: 15%;
  }
  &.animActivated {
    transform: translate(0, 0);

    opacity: 1;
  }
  // &.move {
  //   animation: run 2s 2 ease-in-out;
  // }
}

.popupFounders {
  bottom: 50px;
  left: 45%;

  transition: transform 0.3s ease-out 0.3s, opacity 0.3s ease-out 0.3s;
  transform: translate(50%, 0);
  .arrow {
    path {
      fill: #3480f1;
    }
  }
  .emojiWrapper {
    border: 6px solid #3480f1;
    @media screen and(max-width: 850px) {
      border: 2px solid #3480f1;
    }
  }
  @media screen and(max-width: 850px) {
    bottom: 120px;
    left: 10%;
  }
  &.animActivated {
    transform: translate(0, 0);

    opacity: 1;
  }
  // &.move {
  //   animation: run 2s 2 ease-in-out;
  // }
}

.popupCEOs {
  right: 10%;
  bottom: 170px;

  transition: transform 0.3s ease-out 0.4s, opacity 0.3s ease-out 0.4s;
  transform: translate(50%, 50%);
  .arrow {
    path {
      fill: #14c3db;
    }
  }
  .emojiWrapper {
    border: 6px solid #14c3db;
    @media screen and(max-width: 850px) {
      border: 2px solid #14c3db;
    }
  }
  @media screen and(max-width: 850px) {
    bottom: 60px;
    // left: 10%;
  }
  &.animActivated {
    transform: translate(0, 0);

    opacity: 1;
  }
  // &.move {
  //   animation: run 2s 2 ease-in-out;
  // }
}

@keyframes run {
  0% {
    transform: translate(5px, 5px);
  }
  10% {
    transform: translate(10px, 10px);
  }
  20% {
    transform: translate(15px, 15px);
  }
  30% {
    transform: translate(20px, 20px);
  }
  40% {
    transform: translate(25px, 25px);
  }
  50% {
    transform: translate(30px, 30px);
  }
  60% {
    transform: translate(25px, 25px);
  }
  70% {
    transform: translate(20px, 20px);
  }
  80% {
    transform: translate(15px, 15px);
  }
  90% {
    transform: translate(10px, 10px);
  }
  100% {
    transform: translate(5px, 5px);
  }
}
