.wrapper {
  position: fixed;
  z-index: 2;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  will-change: transform;
  transform: translate(0, 100%);
  transition: transform .3s ease-out;

  width: 100%;
  padding: 12px 20px;

  background: rgba(255, 255, 255, 0.04);

  backdrop-filter: blur(50px);
  &.onScroll {
    transform: translate(0, 0);
  }
}

.getPdfBtn {
  padding: 9px 34px;

  cursor: pointer;

  color: #ffffff;
  border: none;
  border-radius: 1000px;
  outline: none;
  background-color: #030c1d;

  font-family: 'Graphik LC Web';
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  line-height: 120%;

  &:hover {
    color: #030c1d;
    background-color: #ffffff;
  }
}

.menuBtn {
  display: flex;
  align-items: center;
  align-items: stretch;
  justify-content: space-between;

  cursor: pointer;

  border: none;
  outline: none;
  background: none;
}

.menuBtnText {
  margin: 0 10px 0 0;

  color: #d9d9d9;

  font-family: 'Graphik LC Web';
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  line-height: 120%;
}

.burger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  width: 30px;
  height: auto;

  content: '';
  span {
    width: 100%;
    height: 0;

    border: 1px solid #d9d9d9;
  }
}
