@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}
.wrapper {
  position: relative;
}

.row {
  display: flex;

  margin: 0 0 30px;
  @media screen and(max-width: 900px) {
    flex-direction: column-reverse;

    margin: 0 0 10px;
  }
}

.vcCard {
  margin: 0 30px 0 0;
  padding: 70px 160px 80px 80px;

  border-radius: 80px;
  background: rgba(255, 255, 255, 0.09);

  backdrop-filter: blur(21px);
  @media screen and(max-width: 1600px) {
    padding: 60px 124px 60px 60px;

    border-radius: 60px;
  }
  @media screen and(max-width: 1280px) {
    padding: 50px 80px 50px 50px;

    border-radius: 40px;
  }
  @media screen and(max-width: 900px) {
    margin: 0;
  }
  & .cardLink {
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    &:hover {
      color: #ffffff;
      background: #30d6ed;
    }
  }
}

.cardTitle {
  margin: 0 0 25px;

  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 100%;

  @include responsiveFontSize(50px, 60px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(40px, 50px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    margin: 0 0 10px;

    @include responsiveFontSize(24px, 40px, 600px, 1280px);
  }
}

.vcCardSubscribers {
  width: fit-content;
  margin: 0 0 25px;
  padding: 10px 20px;

  color: #ffffff;
  border: 1.5px solid rgba(255, 255, 255, 0.8);
  border-radius: 18px;

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 130%;

  @include responsiveFontSize(20px, 24px, 1600px, 1900px);
  & :not(:last-child) {
    margin: 0 14px 0 0;
  }
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(16px, 20px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    margin: 0 0 10px;

    border-radius: 14px;

    @include responsiveFontSize(16px, 16px, 600px, 1280px);
  }
}

.cardDesc {
  margin: 0 0 55px;

  color: rgba(255, 255, 255, 0.6);

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 120%;

  @include responsiveFontSize(20px, 24px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(16px, 20px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    margin: 0 0 24px;

    @include responsiveFontSize(16px, 16px, 600px, 1280px);
  }
}

.cardLink {
  display: block;

  width: fit-content;
  margin: 0;
  padding: 25px 79px;

  text-decoration: none;
  letter-spacing: -0.05em;

  color: #0a0620;
  border-radius: 100px;
  background: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 80%;

  @include responsiveFontSize(32px, 36px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(18px, 24px, 500px, 1280px);
  }
  @media screen and(max-width: 800px) {
    padding: 12px 44px;
  }
}

.tgCard {
  flex: 1;

  padding: 70px 80px;

  border-radius: 80px 250px 250px 80px;
  background: #29a9eb;
  background-image: url('../../assets/images/tgBackground.svg');
  background-repeat: no-repeat;
  background-position: bottom 0 right 0;
  background-size: 45%;

  backdrop-filter: blur(21px);
  @media screen and(max-width: 1600px) {
    padding: 60px;

    border-radius: 60px 600px 600px 60px;
  }
  @media screen and(max-width: 1280px) {
    padding: 50px;

    border-radius: 40px 600px 600px 40px;
  }
  @media screen and(max-width: 900px) {
    margin: 0 0 10px;
    padding: 30px;

    border-radius: 30px 200px 200px 30px;
    background-size: 34%;
  }
  & .cardLink {
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;

    color: #ffffff;
    background: #138ecc;
    &:hover {
      color: #29a9eb;
      background: #ffffff;
    }
  }
}

.aboutCard {
  width: 100%;
  margin: 0 0 40px;
  padding: 70px 80px 80px;

  border-radius: 80px;
  background: rgba(255, 255, 255, 0.09);

  backdrop-filter: blur(21px);
  @media screen and(max-width: 1600px) {
    padding: 70px 60px 60px;

    border-radius: 60px;
  }
  @media screen and(max-width: 1280px) {
    padding: 60px 50px 60px;

    border-radius: 40px;
  }
  @media screen and(max-width: 900px) {
    padding: 30px;

    border-radius: 30px;
  }
}

.aboutCardRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
  margin: 0 0 20px;
  @media screen and(max-width: 900px) {
    flex-direction: column-reverse;
  }
}

.aboutCardTitle {
  margin: 0;

  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 100%;

  @include responsiveFontSize(50px, 60px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(40px, 50px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(24px, 40px, 600px, 1280px);
  }
}

.aboutCardLogo {
  width: 207px;
  height: auto;
  @media screen and(max-width: 900px) {
    width: 137px;
    margin: 0 0 15px;
  }
}

.aboutCardSecondRow {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 30px;
  @media screen and(max-width: 900px) {
    justify-content: flex-end;
  }
}

.contactsWrapper {
  display: flex;

  gap: 8px;
  @media screen and(max-width: 900px) {
    flex-wrap: wrap;

    gap: 6px;
  }
  @media screen and(max-width: 700px) {
    align-items: flex-end;
    flex-direction: column;
  }
}

.contactsMobileWrapper {
  display: flex;

  gap: 6px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  margin: auto;
  padding: 15px 26px;

  transition: background 0.3s ease-in-out;
  text-decoration: none;

  color: rgba(255, 255, 255, 0.9);
  border-radius: 22px;
  background: rgba(255, 255, 255, 0.09);

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 130%;

  @include responsiveFontSize(20px, 24px, 1600px, 1900px);
  backdrop-filter: blur(12.6692px);
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  @media screen and(max-width: 1600px) {
    padding: 15px 24px;

    border-radius: 20px;

    @include responsiveFontSize(16px, 20px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    padding: 12px 20px;

    border-radius: 18px;

    @include responsiveFontSize(16px, 16px, 600px, 1280px);
  }
  @media screen and(max-width: 900px) {
    max-height: 45px;
  }
}

.linkIcon {
  width: auto;
  height: 100%;
  &:not(:only-child) {
    margin: 0 15px 0 0;
  }
  @media screen and(max-width: 900px) {
    &:not(:only-child) {
      margin: 0 8px 0 0;
    }
  }
}
.linkText {
  white-space: nowrap;
}

.landingLink {
  margin: 0;
  padding: 28px 115px;

  transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
  white-space: nowrap;
  text-decoration: none;

  color: #ffffff;
  border-radius: 1000px;
  background: #e954c3;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 120%;

  @include responsiveFontSize(32px, 36px, 1600px, 1900px);
  &:hover {
    color: #0a0620;
    background: #ffffff;
  }
  @media screen and(max-width: 1600px) {
    padding: 22px 83px;

    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    padding: 20px 73px;

    @include responsiveFontSize(20px, 24px, 500px, 1280px);
  }
  @media screen and(max-width: 800px) {
    width: 100%;
    padding: 18px 0;

    text-align: center;

    border-radius: 50px;
  }
}

.deepFooter {
  display: flex;
  flex-direction: column;
  @media screen and(max-width: 900px) {
    align-items: flex-start;
  }
}

.deepFooterRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin: 0 0 8px;
  }
  @media screen and(max-width: 900px) {
    align-items: flex-start;
    flex-direction: column;
    &:not(:last-child) {
      margin: 0;
    }
  }
}
.inn {
  margin: 0;

  text-align: right;

  color: #585567;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 120%;

  @include responsiveFontSize(20px, 24px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(16px, 20px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(16px, 16px, 600px, 1280px);
  }
}
.privacyLinks {
  display: flex;
  & :not(:last-child) {
    margin: 0 60px 0 0;
  }
  @media screen and(max-width: 900px) {
    align-items: flex-start;
    flex-direction: column;

    margin: 0 0 40px;

    gap: 16px;
  }
}

.privacyLink {
  margin: 0;

  text-align: right;

  color: #585567;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 120%;

  text-underline-offset: 10px;
  @include responsiveFontSize(20px, 24px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(16px, 20px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(16px, 16px, 600px, 1280px);
  }
  @media screen and(max-width: 900px) {
    text-underline-offset: 2px;
  }
}

.copyright {
  margin: 0;

  text-align: right;

  color: #474458;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 120%;

  @include responsiveFontSize(20px, 24px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(16px, 20px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(16px, 16px, 600px, 1280px);
  }
}
