@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}

.wrapper {
  position: relative;
  z-index: 1;

  margin: 0 0 200px;
  @media screen and(max-width: 1600px) {
    margin: 0 0 120px;
  }
  @media screen and(max-width: 1280px) {
    margin: 0 0 100px;
  }
  @media screen and(max-width: 850px) {
    margin: 0 0 200px;
  }
  .title {
    display: flex;
    flex-direction: column;

    font-family: 'Graphik LC Web';
    font-style: normal;

    .titleResearch {
      margin: 0 21% 0 0;

      transition: opacity 0.3s ease-in 0.2s;
      text-align: right;
      letter-spacing: -0.06em;

      opacity: 0;
      color: #ffffff;

      font-weight: 400;
      font-style: italic;
      line-height: 75%;

      @include responsiveFontSize(60px, 70px, 1600px, 1900px);
      @media screen and(max-width: 1600px) {
        @include responsiveFontSize(50px, 60px, 1280px, 1600px);
      }
      @media screen and(max-width: 1280px) {
        @include responsiveFontSize(40px, 60px, 500px, 1280px);
      }
      @media screen and(max-width: 600px) {
        line-height: 90%;
      }

      &.animActivated {
        opacity: 1;
      }
    }

    .howResponse,
    .eCommerce,
    .year {
      font-weight: 700;

      @include responsiveFontSize(160px, 200px, 1600px, 1900px);
      @media screen and(max-width: 1600px) {
        @include responsiveFontSize(120px, 160px, 1280px, 1600px);
      }
      @media screen and(max-width: 1280px) {
        @include responsiveFontSize(50px, 120px, 500px, 1280px);
      }
    }

    .howResponse {
      transition: opacity 0.3s ease-in 0.4s;
      letter-spacing: -0.03em;

      opacity: 0;
      color: #ffffff;

      line-height: 75%;
      &.animActivated {
        opacity: 1;
      }
      @media screen and(max-width: 600px) {
        line-height: 90%;
      }
    }

    .eCommerce {
      transition: opacity 0.3s ease-in 0.6s;
      text-align: right;
      letter-spacing: -0.03em;

      opacity: 0;
      color: #ffffff;

      line-height: 85%;
      &.animActivated {
        opacity: 1;
      }
      @media screen and(max-width: 600px) {
        line-height: 90%;
      }
    }

    .year {
      margin: 0 0 0 6%;

      transition: opacity 0.3s ease-in 0.8s;
      letter-spacing: -0.03em;

      opacity: 0;
      color: #ffffff;

      line-height: 75%;
      &.animActivated {
        opacity: 1;
      }
      @media screen and(max-width: 600px) {
        line-height: 90%;
      }
    }
  }
  .popupIcon {
    width: 10%;
    height: auto;
  }
  .popupText {
    flex: 1 1 auto;
  }
  .popupReduce,
  .popupContractors,
  .popupPriority {
    position: absolute;

    display: flex;
    visibility: hidden;
    align-items: center;
    justify-content: space-between;

    padding: 25px 32px;

    transform: translateZ(0);
    white-space: nowrap;

    opacity: 0;
    color: rgba(255, 255, 255, 0.7);
    border-radius: 22px;
    background: rgba(255, 255, 255, 0.09);

    font-family: 'Graphik LC Web';
    font-weight: 500;
    font-style: normal;
    line-height: 130%;

    @include responsiveFontSize(24px, 36px, 1280px, 1900px);
    will-change: transform, visibility, opacity;
    backdrop-filter: blur(12.6692px);
    & > :not(:last-child) {
      margin: 0 16px 0 0;
    }
    @media screen and(max-width: 1600px) {
      padding: 18px 26px;

      border-radius: 22px;
    }
    @media screen and(max-width: 1280px) {
      padding: 14px 20px;

      border-radius: 18px;

      @include responsiveFontSize(18px, 24px, 500px, 1280px);
    }
    @media screen and(max-width: 800px) {
      padding: 14px 20px;

      border-radius: 12px;
    }
  }
  .popupContractors {
    top: 30%;
    right: 4%;

    transition: transform 0.3s ease-out 0.8s, opacity 0.3s ease-out 0.8s;
    transform: translate(0, 100%);
    @media screen and(max-width: 850px) {
      top: -70px;
    }
    &.animActivated {
      visibility: visible;

      transform: translate(0, 0);

      opacity: 1;
    }
  }
  .popupReduce {
    top: 53%;
    left: 6%;

    transition: transform 0.3s ease-out 1s, opacity 0.3s ease-out 1s;
    transform: translate(0, 100%);
    @media screen and(max-width: 850px) {
      top: unset;
      bottom: -70px;
      left: 0;
    }
    &.animActivated {
      visibility: visible;

      transform: translate(0, 0);

      opacity: 1;
    }
  }
  .popupPriority {
    top: 65%;
    right: 2%;

    transition: transform 0.3s ease-out 1.2s, opacity 0.3s ease-out 1.2s;
    transform: translate(0, 100%);
    @media screen and(max-width: 850px) {
      top: unset;
      right: 0;
      bottom: -130px;
    }
    &.animActivated {
      visibility: visible;

      transform: translate(0, 0);

      opacity: 1;
    }
  }
}
