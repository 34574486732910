.aura,
.aura2 {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;

  visibility: hidden;
  overflow: visible;

  width: 900px;
  height: 900px;

  transform: translate(-50%, -50%);

  will-change: top, left, translate;
  filter: blur(150px);
  @media screen and (max-width: 800px) {
    display: none;
  }
}
.aura {
  background: radial-gradient(50% 50% at 50% 50%, rgba(225, 86, 191, 0.8) 0%, rgba(225, 86, 191, 0) 100%);
  // background: red; 
          // transform: translate(5%, 5%);
}

.aura2 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(119, 30, 151, 0.9) 0%, rgba(119, 30, 151, 0) 100%);
  // background: green;
          // transform: translate(-5%, -5%);
}
