@font-face {
  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;

  src: url('./Graphik-Regular-Web.ttf');
}

@font-face {
  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;

  src: url('./Graphik-Medium-Web.ttf');
}

@font-face {
  font-family: 'Graphik LC Web';
  font-weight: 700;
  font-style: normal;

  src: url('./Graphik-Bold-Web.ttf');
}

@font-face {
  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;

  src: url('./Graphik-RegularItalic-Web.ttf');
}
