@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;

  margin: 0 0 220px;
  @media screen and(max-width: 1600px) {
    margin: 0 0 120px;
  }
  @media screen and(max-width: 1280px) {
    margin: 0 0 100px;
  }
  @media screen and(max-width: 800px) {
    margin: 0 0 60px;
  }
}

.title {
  margin: 0 0 60px 0;

  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 100%;

  @include responsiveFontSize(60px, 70px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(50px, 60px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(30px, 50px, 500px, 1280px);
  }
  @media screen and(max-width: 600px) {
    margin: 0 0 20px;
  }
}

.budgetPercentageWrapper {
  margin: 0 0 30px 0;
  padding: 80px;

  border-radius: 100px;
  background: rgba(255, 255, 255, 0.09);

  backdrop-filter: blur(12.6692px);

  @media screen and(max-width: 1600px) {
    padding: 60px;

    border-radius: 80px;
  }
  @media screen and(max-width: 1280px) {
    padding: 50px;

    border-radius: 60px;
  }
  @media screen and(max-width: 600px) {
    padding: 30px 100px 30px 30px;

    border-radius: 40px;
  }
  @media screen and(max-width: 450px) {
    padding: 30px 60px 30px 30px;

    border-radius: 40px;
  }
}

.budgetPercentageTitleWrapper {
  margin: 0 0 80px;
  @media screen and(max-width: 600px) {
    margin: 0 0 20px;
  }
}

.budgetPercentageTitle {
  margin: 0;

  letter-spacing: -0.05em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 100%;

  @include responsiveFontSize(120px, 160px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(90px, 120px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(60px, 90px, 500px, 1280px);
  }
}

.budgetPercentageDesc {
  margin: 0;

  letter-spacing: -0.02em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 110%;

  @include responsiveFontSize(32px, 40px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(18px, 24px, 500px, 1280px);
  }
}

.budgetPercentageSubDesc {
  margin: 0;

  letter-spacing: -0.02em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-size: 40px;
  font-weight: 400;
  font-style: normal;
  line-height: 110%;

  @include responsiveFontSize(32px, 40px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(18px, 24px, 500px, 1280px);
  }
}

.chartWrapper {
  display: flex;
}

.chartDonutWrapper {
  margin: 0 60px 0 0;
  @media screen and(max-width: 600px) {
    margin: 0 20px 0 0;
  }
}

.chartDonut {
  width: 100%;
  height: auto;

  object-fit: contain;
}

.percentageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.percentageContainer {
  margin: 0 0 32px 0;
}

.chartPercentage {
  margin: 0;

  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 110%;

  @include responsiveFontSize(32px, 40px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(18px, 24px, 500px, 1280px);
  }
}

.chartPercentageDesc {
  margin: 0;

  letter-spacing: -0.03em;

  color: rgba(255, 255, 255, 0.7);

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 110%;

  @include responsiveFontSize(20px, 24px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(14px, 20px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(14px, 14px, 500px, 1280px);
  }
}

.bottomBlocksWrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  gap: 20px;
}

.rightBlockWrapper {
  flex: 1 1 40%;
}

.investWrapper {
  margin: 0 0 20px 0;
  padding: 80px 100px 80px 80px;

  border-radius: 100px;
  background: rgba(255, 255, 255, 0.09);

  backdrop-filter: blur(12.6692px);
  @media screen and(max-width: 1600px) {
    padding: 50px;

    border-radius: 80px;
  }
  @media screen and(max-width: 1280px) {
    padding: 40px;

    border-radius: 60px;
  }
  @media screen and(max-width: 600px) {
    padding: 30px;

    border-radius: 40px;
  }
}

.investTitle {
  margin: 0;

  white-space: nowrap;
  letter-spacing: -0.05em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 100%;

  @include responsiveFontSize(120px, 160px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(90px, 120px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(60px, 90px, 500px, 1280px);
  }
}

.investDesc {
  margin: 0;

  letter-spacing: -0.02em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 110%;

  @include responsiveFontSize(32px, 40px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(18px, 24px, 500px, 1280px);
  }
}

.incitesDesc {
  margin: 0 0 0 30px;

  letter-spacing: -0.03em;

  color: rgba(255, 255, 255, 0.6);

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 100%;

  @include responsiveFontSize(32px, 36px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(18px, 24px, 500px, 1280px);
  }
}

.getPdfBtn {
  display: flex;
  flex: 1 1 40%;
  flex-direction: column;

  padding: 80px 100px 80px 80px;

  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  text-align: left;

  border: none;
  border-radius: 100px;
  outline: none;
  background-color: #e954c3;

  backdrop-filter: blur(12.5px);
  @media screen and(max-width: 1600px) {
    padding: 50px;

    border-radius: 80px;
  }
  @media screen and(max-width: 1280px) {
    padding: 40px;

    border-radius: 60px;
  }
  @media screen and(max-width: 600px) {
    align-items: center;
    flex-direction: row;

    padding: 30px;

    border-radius: 40px;
  }
  &:hover {
    background-color: #ffffff;
    & .getPdfBtnTitle,
    & .getPdfBtnDesc {
      color: #c8d0d1;
    }
    & .getPdfPseudoBtn {
      color: #ffffff;
      background-color: #e954c3;
    }
  }
}

.getPdfBtnTitle {
  margin: 0;

  transition: color 0.3s ease-in-out;
  letter-spacing: -0.05em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 100%;

  @include responsiveFontSize(120px, 160px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(90px, 120px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(60px, 90px, 500px, 1280px);
  }
  @media screen and(max-width: 600px) {
    margin: 0 5px 0 0;
  }
}

.getPdfBtnDesc {
  margin: 0 0 60px;

  transition: color 0.3s ease-in-out;
  letter-spacing: -0.02em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 110%;

  @include responsiveFontSize(32px, 40px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(18px, 24px, 500px, 1280px);
  }
  @media screen and(max-width: 600px) {
    margin: 0;
  }
}

.getPdfPseudoBtn {
  padding: 25px 68px;

  transition: background-color 0.3s ease-in-out;
  text-align: center;
  white-space: nowrap;
  letter-spacing: -0.05em;

  color: #e954c3;
  border-radius: 100px;
  background: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 80%;

  @include responsiveFontSize(32px, 36px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(18px, 24px, 500px, 1280px);
  }
  @media screen and(max-width: 600px) {
    padding: 12px 23px;

    border-radius: 100px;
  }
}
