@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}
.wrapper {
  margin: 0 0 220px;
  @media screen and(max-width: 1600px) {
    margin: 0 0 120px;
  }
  @media screen and(max-width: 1280px) {
    margin: 0 0 100px;
  }
  @media screen and(max-width: 600px) {
    margin: 0;
  }
}
.subTitle {
  margin: 0;

  text-align: center;
  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 120%;

  @include responsiveFontSize(50px, 60px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(40px, 50px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(24px, 40px, 500px, 1280px);
  }
}

.title {
  margin: 0;

  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 700;
  font-style: normal;
  line-height: 80%;

  @include responsiveFontSize(100px, 130px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(80px, 100px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(40px, 80px, 500px, 1280px);
  }
  @media screen and(max-width: 450px) {
    &:last-child {
      text-align: right;
    }
  }
}

.textWrapper {
  display: flex;
  align-items: flex-end;

  margin: 0 0 60px 0;
  @media screen and(max-width: 450px) {
    align-items: unset;
    flex-direction: column-reverse;

    margin: 0 0 20px;
  }
}

.desc {
  margin: 0 23px 0 0;

  text-align: right;
  letter-spacing: -0.03em;

  color: rgba(255, 255, 255, 0.6);

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 110%;

  @include responsiveFontSize(26px, 36px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(18px, 26px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(14px, 18px, 500px, 1280px);
  }
  @media screen and(max-width: 450px) {
    text-align: left;
  }
}

.subscribeWrapper {
}

.success {
  display: flex;
  flex-direction: column;

  margin: 0 0 150px 0;
  & > :nth-child(1) {
    margin: 0 0 0 100px;

    text-align: center;
  }
  & > :nth-child(2) {
    margin: 0 100px 20px 0;

    text-align: center;
  }
}

.successDescWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-child) {
    margin: 0 43px 0 0;
  }
}

.successIcons {
  transform: translate(0, 50%);
  @media screen and(max-width: 800px) {
    width: 60px;
    height: 60px;
  }
}
