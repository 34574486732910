@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}
.wrapper {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100%;
}

.inputWrapper {
  display: flex;
  align-items: center;

  width: 100%;
  max-width: 900px;
  margin: 0 0 16px 0;

  gap: 18px;
  @media screen and(max-width: 850px) {
    align-items: stretch;
    flex-direction: column;

    margin: 0;

    gap: 10px;
  }
}

.input {
  flex: 1 1 50%;

  padding: 14px 26px;

  transition: border 0.3s ease-in-out, color 0.3s ease-in-out;
  letter-spacing: -0.03em;

  color: #ffffff;
  border: 1px solid transparent;
  border-radius: 14px;
  outline: none;
  background: #353147;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 130%;

  @include responsiveFontSize(20px, 24px, 1600px, 1900px);
  backdrop-filter: blur(10px);
  &.onError {
    color: #ff7777;
    border: 1px solid #ff7777;
  }
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(16px, 20px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(16px, 16px, 600px, 1280px);
  }
}

.subscribeBtn {
  flex: 1 1 50%;

  padding: 26px 0;

  cursor: pointer;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
  letter-spacing: -0.05em;

  color: #ffffff;
  border: none;
  border-radius: 50px;
  outline: none;
  background: #3480f1;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 50%;

  @include responsiveFontSize(20px, 24px, 1600px, 1900px);

  &:hover {
    color: #29a9eb;
    background: #ffffff;
  }
  @media screen and(max-width: 1600px) {
    padding: 20px 0;
  }
}

.privacyText {
  display: block;

  margin: 0 0 0 -5%;

  opacity: 0.3;
  @media screen and(max-width: 850px) {
    margin: 0 20px 5px;
  }
}

.privacyLink,
.privacyText {
  letter-spacing: -0.05em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 50%;

  @include responsiveFontSize(18px, 20px, 1600px, 1900px);
  text-underline-offset: 10px;
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(16px, 18px, 1280px, 1600px);
    text-underline-offset: 5px;
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(14px, 16px, 600px, 1280px);
    text-underline-offset: 2px;
  }
  @media screen and(max-width: 850px) {
    line-height: 90%;
  }
}
