@import 'normalize.css';
@import './assets/fonts/fonts.css';

html {
  overflow-x: hidden;
}

html,
body {
  margin: 0;
  padding: 0;

  color: #ffffff;

  font-family: Graphik LC Web;
  position: relative;
  scroll-behavior: smooth;
  overflow-y: unset !important;
}

* {
  box-sizing: border-box;
}

#root {
  position: relative;

  background-color: #0a0620;
}

h1,
h2 {
  margin: 0;
  padding: 0;
}

ul,
li {
  margin: 0;
  padding: 0;

  list-style-type: none;
}
