@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}
.wrapper {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: space-between;

  width: 100%;
  margin: 0 auto 260px;
  @media screen and(max-width: 1600px) {
    margin: 0 0 120px;
  }
  @media screen and(max-width: 1280px) {
    margin: 0 0 100px;
  }
  @media screen and(max-width: 1280px) {
    margin: 0 0 100px;
  }
}

.leftSide {
  position: sticky;
  top: 150px;

  display: flex;
  flex: 1 1 25%;
  flex-direction: column;

  height: 100%;
  margin: 0 80px 0 0;
}

.anchorLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 15px 38px;

  white-space: nowrap;
  text-decoration: none;
  letter-spacing: -0.03em;

  color: #6c6a79;
  border: 1px solid #6c6a79;
  border-radius: 50px;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 140%;

  @include responsiveFontSize(32px, 36px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    padding: 14px 32px;

    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    padding: 13px 24px;

    @include responsiveFontSize(18px, 24px, 500px, 1280px);
  }
  .lockerIcon {
    margin: 0 15px 0 0;
  }
  &:not(:last-child) {
    margin: 0 0 16px 0;
  }
  &.active {
    color: #ffffff;
    border: 1px solid #ffffff;
  }
  &.active .lockerIcon {
    path {
      fill: #ffffff;
    }
  }
}

.rightSide {
  flex: 1 1 60%;
}
