@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}

.wrapper {
  z-index: 1;
  position: relative;

  width: 100%;
  margin: 0 auto 220px;
  @media screen and(max-width: 1600px) {
    margin: 0 0 120px;
  }
  @media screen and(max-width: 1280px) {
    margin: 0 0 100px;
  }
  @media screen and(max-width: 850px) {
    margin: 0 0 80px;
  }
}

.title {
  text-align: center;
  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-size: 70px;
  font-weight: 500;
  font-style: normal;
  line-height: 100%;

  @include responsiveFontSize(50px, 70px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(40px, 50px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(24px, 40px, 500px, 1280px);
  }
  .transparent {
    opacity: 0.6;
  }
  .normal {
    opacity: 0.6;
    &:nth-of-type(1) {
      transition: opacity 0.5s ease-in-out 1s;
    }
    &:nth-of-type(3) {
      transition: opacity 0.5s ease-in-out 1.2s;
    }
    &:nth-of-type(5) {
      transition: opacity 0.5s ease-in-out 1.4s;
    }
  }
  &.animActivated .normal {
    opacity: 1;
  }
}
