@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}
.wrapper {
  position: relative;
  z-index: 1;

  margin: 0 0 120px;
  @media screen and(max-width: 1280px) {
    margin: 0 0 80px;
  }
}

.titleTextWrapper {
  position: relative;
}

.videoBlock {
  position: absolute;
  top: 11%;
  left: 9%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 40px 10px 10px;

  border-radius: 170px 40px 40px 170px;
  background: rgba(255, 255, 255, 0.14);

  backdrop-filter: blur(10px);
}

.playBtnVideoWrapper {
  position: relative;

  margin: 0 24px 0 0;
}

.playerWrapper {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  width: 120px;
  height: 120px;

  border: 2px solid #e8e8f0;
  border-radius: 50%;

  isolation: isolate;
}

.playBtn {
  position: absolute;
  top: 0;
  right: 0;

  cursor: pointer;
}

.player {
  width: 100%;
  height: 100%;
}

.authorText {
  margin: 0 0 6px;

  letter-spacing: -0.03em;

  color: #e8e8f0;

  font-family: 'Graphik LC Web';
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: 110%;
}

.authorName {
  margin: 0;

  letter-spacing: -0.03em;

  color: rgba(255, 255, 255, 0.6);

  font-family: 'Graphik LC Web';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 120%;
}

.title {
  margin: 0;

  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 700;
  font-style: normal;
  line-height: 70%;

  @include responsiveFontSize(160px, 200px, 1600px, 1900px);
  &:nth-child(2) {
    margin: 0 0 0 30%;
  }
  &:last-child {
    margin: 0 0 140px;
  }
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(120px, 160px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(38px, 120px, 500px, 1280px);
  }
  @media screen and(max-width: 800px) {
    &:nth-child(1) {
      margin: 0;

      text-align: right;
    }
    &:nth-child(2) {
      margin: 0 0 10px;

      text-align: left;
    }
    &:last-child {
      margin: 0;
    }
  }
}

.desc {
  margin: 0 0 -50px;

  letter-spacing: -0.03em;

  color: #989da9;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 105%;

  @include responsiveFontSize(32px, 36px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(14px, 24px, 500px, 1280px);
  }
  @media screen and(max-width: 800px) {
    margin: 0 0 20px;
  }
}

.textWrapper {
  display: flex;
  align-items: flex-end;

  margin: 0 0 120px 5%;
  & .title {
    margin: 0 125px 0 0;
  }
  @media screen and(max-width: 800px) {
    margin: 0;
  }
}
.popupIcon {
  width: 10%;
  height: auto;
}
.popupText {
  flex: 1 1 auto;

  white-space: nowrap;
}
.popupApps,
.popupResearch,
.popupBranding {
  position: absolute;

  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: space-between;

  padding: 25px 32px;

  transform: translateZ(0);

  opacity: 0;
  color: rgba(255, 255, 255, 0.7);
  border-radius: 22px;
  background: rgba(255, 255, 255, 0.09);

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 130%;

  @include responsiveFontSize(24px, 36px, 1280px, 1900px);
  will-change: transform, visibility, opacity;
  backdrop-filter: blur(12.6692px);
  will-change: transform, visibility, opacity;
  backdrop-filter: blur(12.6692px);
  @media screen and(max-width: 1600px) {
    padding: 18px 26px;
  }
  @media screen and(max-width: 1280px) {
    top: 0;

    padding: 14px 20px;

    @include responsiveFontSize(18px, 24px, 500px, 1280px);
  }
  & > :not(:last-child) {
    margin: 0 16px 0 0;
  }
}
.popupApps {
  top: -14%;
  left: 20%;

  transition: transform 0.3s ease-out 0.8s, opacity 0.3s ease-out 0.8s;
  transform: translate(0, 100%);
  &.animActivated {
    visibility: visible;

    transform: translate(0, 0);

    opacity: 1;
  }
  @media screen and(max-width: 1280px) {
    top: -40px;
    left: 0;
  }
}
.popupResearch {
  top: 55%;
  left: 6%;

  transition: transform 0.3s ease-out 1s, opacity 0.3s ease-out 1s;
  transform: translate(0, 100%);
  &.animActivated {
    visibility: visible;

    transform: translate(0, 0);

    opacity: 1;
  }
  @media screen and(max-width: 1280px) {
    top: 55%;
    left: 50%;
  }
}
.popupBranding {
  top: 62%;
  right: 1%;

  transition: transform 0.3s ease-out 1.2s, opacity 0.3s ease-out 1.2s;
  transform: translate(0, 100%);
  &.animActivated {
    visibility: visible;

    transform: translate(0, 0);

    opacity: 1;
  }
  @media screen and(max-width: 1280px) {
    top: -40px;
  }
}

.casesWrapper {
  display: flex;
  justify-content: space-between;

  margin: 0 0 30px;
  @media screen and(max-width: 800px) {
    flex-direction: column;
  }
}

.case {
  flex: 1;

  padding: 50px 50px 80px;

  border-radius: 80px;

  backdrop-filter: blur(12.6692px);
  &:not(:last-child) {
    margin: 0 35px 0 0;
  }
  @media screen and(max-width: 1600px) {
    padding: 40px 40px 60px;

    border-radius: 60px;
  }
  @media screen and(max-width: 1280px) {
    padding: 30px 30px 50px;

    border-radius: 50px;
  }
  @media screen and(max-width: 800px) {
    &:not(:last-child) {
      margin: 0 0 10px;
    }
  }
}

.nb {
  background: #be2a34;
}

.hyperpc {
  background: #3c3939;
}

.iddis {
  background: #0da24e;
}
.caseHeader {
  position: relative;
}

.iddisLink {
  position: absolute;
  top: -10px;
  right: -10px;
}
.iddisLinkIcon {
}

.caseLogoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: 100px;
  margin: 0 0 32px;
  padding: 30px 40px;

  border-radius: 30px;
  background: #ffffff;
  @media screen and(max-width: 800px) {
    width: fit-content;
    max-height: 80px;
    margin: 0 0 16px;
    padding: 10px 18px;

    border-radius: 20px;
  }
}

.caseLogo {
}

.caseDesc {
  margin: 0;

  letter-spacing: -0.03em;

  color: rgba(255, 255, 255, 0.8);

  font-family: 'Graphik LC Web';
  font-size: 36px;
  font-weight: 400;
  font-style: normal;
  line-height: 120%;

  @include responsiveFontSize(32px, 36px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(14px, 24px, 500px, 1280px);
  }
}

.casesLInk {
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  margin: 0 auto;
  padding: 25px 74px;

  transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
  text-align: center;
  text-decoration: none;
  letter-spacing: -0.05em;

  color: #0a0620;
  border-radius: 100px;
  background: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 80%;

  @include responsiveFontSize(32px, 36px, 1600px, 1900px);
  &:hover {
    color: #ffffff;
    background: #29a9eb;
  }

  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(14px, 24px, 500px, 1280px);
  }
  @media screen and(max-width: 800px) {
    padding: 15px 89px;
  }
}
