@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}
.wrapper {
  position: relative;
  z-index: 1;

  display: flex;

  margin: 0 0 350px 0;
  padding: 60px 55px 80px;

  border-radius: 150px;
  background: rgba(255, 255, 255, 0.09);

  backdrop-filter: blur(10px);
  @media screen and(max-width: 1600px) {
    margin: 0 0 220px;

    border-radius: 120px;
  }
  @media screen and(max-width: 1280px) {
    margin: 0 0 100px;

    border-radius: 90px;
  }
  @media screen and(max-width: 900px) {
    align-items: stretch;
    flex-direction: column;

    margin: 0 0 80px;
    padding: 20px 20px 60px;

    border-radius: 50px;
  }
}

.leftSide {
  display: flex;
  flex: 1 1 50%;
  flex-direction: column;
  justify-content: flex-end;

  margin: 0 15px 0 0;
  @media screen and(max-width: 900px) {
    margin: 0;
  }
}
.imgContainer {
  position: relative;

  width: 100%;
  height: 100%;
  @media screen and(max-width: 900px) {
    height: 50%;
    padding: 50% 0 0px;
  }
}
.imgWrapper {
  position: absolute;
  bottom: 0;

  width: 110%;
  margin: 0% 0 0 -3%;
  padding: 70% 0 0 0;

  transform: translateZ(0);

  background-image: url('../../assets/images/strategyText.png');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
}

.rocketImage {
  position: absolute;
  top: 50px;
  right: 50px;

  width: 25%;

  animation: run 2s infinite linear;
}

.textBlock {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  @media screen and(max-width: 900px) {
    justify-content: center;

    margin: 0 0 30px;
  }
}

.pseudoBtnsWrapper {
  z-index: 1;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  gap: 8px;
  @media screen and(max-width: 900px) {
    gap: 5px;
  }
}
.pseudoBtnIcon {
  width: 10%;
  height: auto;
}
.pseudoBtnText {
  flex: 1 1 auto;

  white-space: nowrap;
}
.pseudoBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: fit-content;
  padding: 15px 22px;

  color: #ffffff;
  border-radius: 18px;
  background: rgba(255, 255, 255, 0.09);

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 130%;

  @include responsiveFontSize(20px, 24px, 1600px, 1900px);
  backdrop-filter: blur(10px);
  & :not(:last-child) {
    margin: 0 14px 0 0;
  }
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(16px, 20px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    padding: 12px 18px;

    border-radius: 15px;

    @include responsiveFontSize(16px, 16px, 600px, 1280px);
  }
  @media screen and(max-width: 800px) {
    padding: 10px 14px;

    border-radius: 12px;

    @include responsiveFontSize(16px, 16px, 600px, 1280px);
    & :not(:last-child) {
      margin: 0 8px 0 0;
    }
  }
}

.wtfBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  cursor: pointer;

  color: #211d35;
  border: none;
  border-radius: 50%;
  outline: none;
  background: rgba(255, 255, 255, 0.62);

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 130%;
}

.rightSide {
  padding: 62px;

  border-radius: 80px;
  background: rgba(255, 255, 255, 0.09);

  backdrop-filter: blur(10px);
  @media screen and(max-width: 1600px) {
    padding: 40px;

    border-radius: 66px;
  }
  @media screen and(max-width: 1280px) {
    padding: 25px;

    border-radius: 50px;
  }
}

.rightSideList {
  display: flex;
  flex-direction: column;
  & :nth-child(odd) {
    text-align: left;
  }
  & :nth-child(even) {
    text-align: right;
  }
}

.rightSideItem {
  text-align: center;
  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 99%;

  @include responsiveFontSize(32px, 36px, 1600px, 1900px);
  &:not(:last-child) {
    margin: 0 0 40px;
  }
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(18px, 24px, 500px, 1280px);
    &:not(:last-child) {
      margin: 0 0 20px;
    }
  }

  .transparent {
    opacity: 0.5;
  }
}

.linkToLanding {
  position: absolute;
  bottom: 0;
  left: 50%;

  padding: 28px 160px;

  transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
  transform: translate(-50%, 50%);
  text-align: center;
  text-decoration: none;

  color: #ffffff;
  border-radius: 1000px;
  background: #ef1c25;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 120%;

  @include responsiveFontSize(32px, 36px, 1600px, 1900px);
  &:hover {
    color: #ef1c25;
    background: #ffffff;
  }
  @media screen and(max-width: 1600px) {
    padding: 22px 100px;

    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    padding: 22px 74px;

    @include responsiveFontSize(20px, 24px, 500px, 1280px);
  }
  @media screen and(max-width: 900px) {
    padding: 18px 60px;
  }
}

@keyframes run {
  0% {
    transform: translate(5px, -5px) rotate(0);
  }
  25% {
    transform: translate(15px, -15px) rotate(-5deg);
  }
  50% {
    transform: translate(30px, -30px) rotate(0);
  }
  75% {
    transform: translate(15px, -15px) rotate(5deg);
  }
  100% {
    transform: translate(5px, -5px) rotate(0);
  }
}
