@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}
.wrapper {
  position: relative;

  overflow: hidden;

  margin: 0 0 140px;
  padding: 70px 80px 400px;

  border-radius: 100px;
  background: rgba(255, 255, 255, 0.09);

  backdrop-filter: blur(12.5px);
  @media screen and(max-width: 1600px) {
    margin: 0 0 100px;
    padding: 50px 60px 340px;

    border-radius: 80px;
  }
  @media screen and(max-width: 1280px) {
    margin: 0 0 80px;
    padding: 40px 50px 270px;

    border-radius: 60px;
  }
  @media screen and(max-width: 850px) {
    margin: 0 0 20px;
    padding: 30px 30px 270px;

    border-radius: 40px;
  }
}

.background {
  position: absolute;
  right: 0;
  bottom: 0;

  max-width: 100%;
}

.title {
  margin: 0 0 30px;

  letter-spacing: -0.02em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 100%;

  @include responsiveFontSize(50px, 70px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(40px, 50px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(34px, 40px, 400px, 1280px);
  }
}

.cyan {
  color: #14c3db;
}

.desc {
  letter-spacing: -0.03em;

  color: rgba(255, 255, 255, 0.5);

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 110%;

  @include responsiveFontSize(32px, 36px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(18px, 24px, 500px, 1280px);
  }
}
