@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}

.appContainer {
  width: 100%;
  height: 100%;
  @media screen and(max-width: 600px) {
    overflow: hidden;
  }
}

.pageWrapper {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 70px 130px;

  @media screen and(max-width: 1600px) {
    padding: 0 40px 130px;
  }
  @media screen and(max-width: 1280px) {
    padding: 0 20px 130px;
  }
  @media screen and(max-width: 800px) {
    background-image: url('../../assets/images/mainBackground.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.excerption {
  margin: 0 auto 200px;

  transition: opacity 1s ease-in-out;
  @media screen and(max-width: 1600px) {
    margin: 0 auto 120px;
  }
  @media screen and(max-width: 1280px) {
    margin: 0 auto 100px;
  }
  .transparent {
    opacity: 0.6;
  }
  .normal {
    opacity: 0.6;

    will-change: opacity;
    &:nth-child(1) {
      transition: opacity 0.5s ease-in-out;
    }
    &:nth-child(2) {
      transition: opacity 0.5s ease-in-out 0.2s;
    }
    &:nth-child(3) {
      transition: opacity 0.5s ease-in-out 0.4s;
    }
    &:nth-child(4) {
      transition: opacity 0.5s ease-in-out 0.6s;
    }
    &:nth-child(5) {
      transition: opacity 0.5s ease-in-out 0.8s;
    }
    &:nth-child(6) {
      transition: opacity 0.5s ease-in-out 1s;
    }
    &:nth-child(7) {
      transition: opacity 0.5s ease-in-out 1.2s;
    }
    &:nth-child(8) {
      transition: opacity 0.5s ease-in-out 1.4s;
    }
    &:nth-child(9) {
      transition: opacity 0.5s ease-in-out 1.6s;
    }
    &:nth-child(10) {
      transition: opacity 0.5s ease-in-out 1.8s;
    }
  }
  &.animActivated .normal {
    opacity: 1;
  }
  .excerptionText {
    margin: 0;

    text-align: center;
    letter-spacing: -0.03em;

    color: #ffffff;

    font-family: 'Graphik LC Web';
    font-weight: 400;
    font-style: normal;
    line-height: 100%;

    @include responsiveFontSize(50px, 60px, 1600px, 1900px);
    @media screen and(max-width: 1600px) {
      @include responsiveFontSize(40px, 50px, 1280px, 1600px);
    }
    @media screen and(max-width: 1280px) {
      @include responsiveFontSize(18px, 40px, 500px, 1280px);
    }
  }
}
