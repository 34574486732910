@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}

.wrapper {
  z-index: 1;
  position: relative;

  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  margin: 0 0 200px;

  gap: 20px;
  @media screen and(max-width: 1600px) {
    margin: 0 0 120px;
  }
  @media screen and(max-width: 1280px) {
    margin: 0 0 100px;
  }
  @media screen and(max-width: 850px) {
    flex-wrap: wrap;

    gap: 10px;
  }
}

.interviewBlock,
.companiesBlock,
.incitesBlockBtn {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;

  margin: 0;
  padding: 0;
  padding: 80px;

  border-radius: 100px;
  background: rgba(255, 255, 255, 0.09);

  backdrop-filter: blur(12.6692px);
  @media screen and(max-width: 1600px) {
    padding: 50px;

    border-radius: 80px;
  }
  @media screen and(max-width: 1280px) {
    padding: 35px;

    border-radius: 60px;
  }
  @media screen and(max-width: 850px) {
    padding: 25px;

    border-radius: 40px;
  }
}

.interviewBlockTitle,
.companiesBlockTitle,
.incitesBlockBtnTitle {
  width: 100%;
  margin: 0;

  text-align: left;
  letter-spacing: -0.05em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 100%;

  @include responsiveFontSize(160px, 200px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(130px, 160px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(40px, 130px, 500px, 1280px);
  }
}

.interviewBlockSubTitle,
.companiesBlockSubTitle,
.incitesBlockBtnSubTitle {
  width: 100%;
  margin: 0;

  text-align: left;
  letter-spacing: -0.02em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 100%;

  @include responsiveFontSize(40px, 50px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(30px, 40px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(18px, 30px, 500px, 1280px);
  }
}

.interviewBlock {
  @media screen and(max-width: 850px) {
    flex: 1 1 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.interviewBlockTitle {
}
.interviewBlockSubTitle {
  margin: 0 0 60px;
  @media screen and(max-width: 850px) {
    margin: 0 0 20px;
  }
}

.interviewBlockDesc {
  width: 100%;
  margin: 0;

  letter-spacing: -0.03em;

  color: rgba(255, 255, 255, 0.5);

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 110%;

  @include responsiveFontSize(18px, 36px, 500px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(18px, 24px, 500px, 1280px);
  }
  @media screen and(max-width: 850px) {
    flex: 1 1 100%;
  }
}

.companiesBlock {
  @media screen and(max-width: 850px) {
    flex: 1;
  }
}

.companiesBlockTitle {
}

.companiesBlockSubTitle {
}

.incitesBlockBtn {
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  border: none;
  outline: none;
  background-color: #30d6ed;

  backdrop-filter: blur(12.5px);
  &:hover {
    background-color: #ffffff;
    & .incitesBlockBtnTitle,
    & .incitesBlockBtnSubTitle {
      color: #c8d0d1;
    }
    & .incitesBlockBtnPseudoBtn {
      color: #ffffff;
      background-color: #30d6ed;
    }
  }
  @media screen and(max-width: 850px) {
    flex: 1;

    margin: 0;
  }
}

.incitesBlockBtnTitle {
  transition: color 0.3s ease-in-out;
}

.incitesBlockBtnSubTitle {
  margin: 0 0 60px;

  transition: color 0.3s ease-in-out;
  @media screen and(max-width: 850px) {
    margin: 0 0 24px;
  }
}

.incitesBlockBtnPseudoBtn {
  padding: 25px 68px;

  transition: background-color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  text-align: center;
  white-space: nowrap;
  letter-spacing: -0.05em;

  color: #30d6ed;
  border-radius: 100px;
  background-color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: italic;
  line-height: 80%;

  @include responsiveFontSize(18px, 36px, 500px, 1900px);
  @media screen and(max-width: 1600px) {
    padding: 22px 60px;

    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    padding: 20px 42px;

    @include responsiveFontSize(18px, 24px, 500px, 1280px);
  }
  @media screen and(max-width: 850px) {
    flex: 1;

    padding: 20px 30px;
  }
}

.linkBlock {
  transform: rotate(180deg);

  writing-mode: tb-rl;
  @media screen and(max-width: 850px) {
    flex: 1 1 100%;

    transform: rotate(0);

    writing-mode: horizontal-tb;
  }
}

.linkDesc,
.link {
  display: flex;
  align-items: center;

  @include responsiveFontSize(20px, 24px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(16px, 20px, 1280px, 1600px);
  }
}

.linkDesc {
  margin: 0;

  letter-spacing: -0.03em;

  color: rgba(255, 255, 255, 0.6);

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 100%;
}

.link {
  text-decoration: none;
  letter-spacing: -0.03em;

  color: rgba(255, 255, 255, 0.7);

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 100%;
  &span {
    margin: 0 2px 0 0;
  }
  svg {
    transform: rotate(90deg);
  }
}
