@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}
.wrapper {
  position: relative;
  z-index: 1;

  overflow: hidden;

  margin: 0 0 220px;
  padding: 200px 70px 140px;

  border-radius: 30px;
  background: rgba(255, 255, 255, 0.09);

  isolation: isolate;
  @media screen and(max-width: 1600px) {
    margin: 0 0 120px;
    padding: 170px 70px 120px;
  }
  @media screen and(max-width: 1280px) {
    margin: 0 0 100px;
    padding: 130px 60px 70px;
  }
  @media screen and(max-width: 1100px) {
    margin: 0 0 100px;
    padding: 130px 40px 70px;
  }
  @media screen and(max-width: 800px) {
    padding: 130px 10px 70px;

    border-radius: 20px;
  }
}

.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 20px;

  background: #353147;

  backdrop-filter: blur(10px);
  @media screen and(max-width: 800px) {
    padding: 10px 15px;
  }
}

.headerDot {
  display: block;

  width: 20px;
  height: 20px;

  border-radius: 50%;
  background: #474458;

  backdrop-filter: blur(9.86651px);
  @media screen and(max-width: 800px) {
    width: 10px;
    height: 10px;
  }
  &:not(:last-child) {
    margin: 0 5px 0 0;
  }
}
.textWrapper {
  position: relative;

  margin: 0 auto 60px;
  @media screen and(max-width: 800px) {
    margin: 0 0 30px;
  }
}
.subTitle {
  margin: 0;

  text-align: center;
  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-size: 100px;
  font-weight: 400;
  font-style: italic;
  line-height: 90%;

  @include responsiveFontSize(81px, 100px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(60px, 81px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(18px, 60px, 600px, 1280px);
  }
}

.title {
  width: 100%;
  margin: 0;

  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 700;
  font-style: normal;
  line-height: 80%;

  @include responsiveFontSize(150px, 180px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(120px, 150px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(40px, 120px, 600px, 1280px);
  }
  &:not(:last-child) {
    margin: 0 30px 0 0;
  }
  &:nth-child(3) {
    text-align: left;
  }
  &:nth-child(4) {
    text-align: right;
  }
}

.desc {
  letter-spacing: -0.03em;

  color: rgba(255, 255, 255, 0.6);

  font-family: 'Graphik LC Web';
  font-weight: 400;
  font-style: normal;
  line-height: 110%;

  @include responsiveFontSize(32px, 36px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(24px, 32px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(14px, 24px, 500px, 1280px);
  }
}

.instructionPopup {
  position: absolute;
  top: 37%;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 30px 50px;

  transition: transform 0.3s ease-out, opacity.3s ease-out;
  transform: translate3d(0, 200%, 0);

  opacity: 0;
  color: rgba(255, 255, 255, 0.7);
  border-radius: 22px;
  background: rgba(255, 255, 255, 0.09);

  font-family: 'Graphik LC Web';
  font-weight: 500;
  font-style: normal;
  line-height: 110%;

  @include responsiveFontSize(24px, 36px, 1600px, 1900px);
  will-change: transform, opacity;
  backdrop-filter: blur(15px);
  &::after {
    position: absolute;
    top: 0;
    left: 0;

    width: 32px;
    height: 32px;

    content: '';
    transform: translate(-30%, -30%);

    border-radius: 50%;
    background: #e954c3;
  }
  @media screen and(max-width: 1600px) {
    padding: 24px 40px;

    border-radius: 30px;

    @include responsiveFontSize(24px, 24px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    top: 27%;

    padding: 10px 15px;

    border-radius: 12px;

    @include responsiveFontSize(14px, 24px, 500px, 1280px);
  }
  @media screen and(max-width: 800px) {
    top: -70px;
    right: 0;
    &::after {
      width: 16px;
      height: 16px;
    }
  }
  &.animActivated {
    transform: translate3d(0, 0, 0);

    opacity: 1;
  }
}

.subscribeWrapper {
  display: flex;
  justify-content: center;
}

.success {
  width: 80%;
  margin: 0 auto;
  .textWrapper {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  & .title {
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: right;
    }
  }
}

.descWrapper {
  display: flex;
  align-items: flex-start;
}

.starsIcon {
  width: 120px;
  height: 120px;
  margin: 60px 25px 0 0;
}
