@function calcResponsiveFontSize($f-min, $f-max, $v-min, $v-max) {
  $k: ($f-max - $f-min)/ ($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  @return calc(#{$k} * 100vw + #{$b});
}

@mixin responsiveFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: $f-min;

  @media (min-width: $w-min) {
    font-size: calcResponsiveFontSize($f-min, $f-max, $w-min, $w-max);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}
.wrapper {
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: space-between;

  width: 100%;
  margin: 0 auto 260px;
  @media screen and(max-width: 1600px) {
    margin: 0 0 120px;
  }
  @media screen and(max-width: 1280px) {
    margin: 0 0 100px;
  }
  @media screen and(max-width: 850px) {
    flex-direction: column;
  }
}

.leftSide {
  position: sticky;
  top: 150px;

  height: 100%;
  @media screen and(max-width: 850px) {
    position: static;

    margin: 0 0 40px;
  }
}

.leftSideTitle {
  margin: 0;

  transition: opacity 1s ease-in-out;
  letter-spacing: -0.03em;

  color: #ffffff;

  font-family: 'Graphik LC Web';
  font-weight: 700;
  font-style: normal;
  line-height: 86%;

  @include responsiveFontSize(100px, 140px, 1600px, 1900px);
  @media screen and(max-width: 1600px) {
    @include responsiveFontSize(80px, 100px, 1280px, 1600px);
  }
  @media screen and(max-width: 1280px) {
    @include responsiveFontSize(50px, 80px, 500px, 1280px);
  }
  .transparent {
    opacity: 0.6;
  }
  .normal {
    opacity: 0.6;
    &:nth-of-type(4) {
      transition: opacity 0.5s ease-in-out;
    }
    &:nth-of-type(5) {
      transition: opacity 0.5s ease-in-out 0.2s;
    }
  }
  &.animActivated .normal {
    opacity: 1;
  }
}

.videoBlock {
  position: absolute;
  top: 96%;
  left: -3%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 40px 10px 10px;

  border-radius: 170px 40px 40px 170px;
  background: rgba(255, 255, 255, 0.14);

  backdrop-filter: blur(10px);
}

.playBtnVideoWrapper {
  position: relative;

  margin: 0 24px 0 0;
}

.playerWrapper {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  width: 120px;
  height: 120px;

  border: 2px solid #e8e8f0;
  border-radius: 50%;

  isolation: isolate;
}

.playBtn {
  position: absolute;
  top: 0;
  right: 0;

  cursor: pointer;
}

.authorWrapper {
}

.authorText {
  margin: 0 0 6px;

  letter-spacing: -0.03em;

  color: #e8e8f0;

  font-family: 'Graphik LC Web';
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: 110%;
}

.authorName {
  margin: 0;

  letter-spacing: -0.03em;

  color: rgba(255, 255, 255, 0.6);

  font-family: 'Graphik LC Web';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 120%;
}

.rightSide {
  display: flex;
  flex-direction: column;
}
